
import {extendTheme } from '@chakra-ui/react';
import textStyles from './custom_text';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import "@fontsource-variable/manrope";

const globalColors = {
  'neutral-0':'#fff',
  'neutral-100': '#fafafa',
  'neutral-150': '#f5f5f5',
  'neutral-180': '#D5E1E7',
  'neutral-200': '#A7B1B8',
  'neutral-300': '#677689',
  'neutral-400': '#57626C',
  'neutral-800': '#464646',
  'neutral-850': '#2C2C2C',
  'neutral-900': '#262626',


  'green-100': '#F5FFF9',
  'green-200': '#6FCF97',
  'green-300': '#219653',


  'blue-10': 'rgba(149, 217, 255, 0.3)',
  'blue-50': '#0091FF',
  'blue-100': '#8DCEFD',
  'blue-200': '#2D91EF',
  'blue-300': '#1F61FF',
  'blue-800': '#102E49',

  'red-200': '#FF7C7C',
  'red-300': '#E95959',

  'purple-50': '#D3E6FF',
  'purple-100': '#E7EEFF',
  'purple-200': '#7F6EEA',
  'purple-300': '#503C7A',
  'purple-400': '#6D59EB',

  'yellow-300':'#FFB724',

  'transparent-blue': 'rgba(141,206,253,0.3)',
  'transparent-blue-dark': 'rgba(141,206,253,0.1)',

  'gradient-purple-blue-light': `linear-gradient(267.04deg, rgba(109, 196, 253, 0.081) 0.29%, rgba(74, 123, 237, 0.075) 98.36%)`,
  'gradient-purple-blue': `linear-gradient(267.04deg, rgba(73, 122, 236, 1) 0.29%, rgba(110, 198, 254, 1) 98.36%)`,
  'transparent-white-light': 'rgba( 255, 255, 255, 0.15 )',
  'transparent-white': 'rgba( 255, 255, 255, 0.18 )'
}

const tokens = {
  colors: {
    ...globalColors,
    light: {
      'bg-default': globalColors['neutral-150'],
      'bg-light': globalColors['neutral-0'],
      'bg-muted': globalColors['neutral-100'],
      'bg-success': globalColors['green-100'],
      'bg-success-strong': globalColors['green-200'],
      'bg-primary': globalColors['blue-10'],
      
      'bg-primary-strong': globalColors['blue-300'],
      'bg-decorative-two': `rgba(globalColors['red-200'], 40%)`,
      'bg-decorative-purple': globalColors['purple-100'],
      'bg-decorative-purple-strong': globalColors['purple-50'],
      'bg-glass': globalColors['gradient-purple-blue-light'],
      
      // - text -
      'text-weak': globalColors['neutral-200'],
      'text-readable-weak': globalColors['neutral-300'],
      'text-medium': globalColors['neutral-400'],
      'text-default': globalColors['blue-800'],
      
      'text-primary-weak': `rgba(141, 206, 253, 0.5)`,
      'text-primary': globalColors['blue-300'],
      'text-primary-medium': globalColors['blue-200'],
      
      'text-success': globalColors['green-300'],
      'text-decorative-purple': globalColors['purple-300'],

      'text-on': globalColors['neutral-0'],

      
      // - border - 
      'border-light': globalColors['neutral-180'],
      'border-glass': globalColors['blue-50'],
      'border-primary': globalColors['blue-300'],
      'border-success': globalColors['green-200'],
    },

    dark: {
      'bg-default': globalColors['neutral-900'],
      'bg-light': globalColors['neutral-800'],
      'bg-muted': globalColors['neutral-850'],
      'bg-success': globalColors['green-300'],
      'bg-success-strong': globalColors['green-200'],
      'bg-primary': 'blue.500',
      'bg-primary-strong':  'blue.600',

      'bg-decorative-two': `rgba(globalColors['red-200'], 40%)`,
      'bg-decorative-purple': globalColors['purple-200'],
      'bg-decorative-purple-strong': globalColors['purple-400'],
      'bg-glass': globalColors['transparent-white-light'],

      // - text -
      'text-weak': globalColors['neutral-300'],
      'text-readable-weak': globalColors['neutral-200'],
      'text-medium': globalColors['neutral-180'],
      'text-default': globalColors['neutral-0'],

      'text-primary-weak': `rgba(141, 206, 253, 0.6)`,
      'text-primary': globalColors['blue-50'],
      'text-primary-medium': 'blue.50',
      
      'text-success': globalColors['green-300'],
      'text-decorative-purple': 'purple.50',
      'text-on': globalColors['neutral-0'],

      // - border - 
      'border-light': globalColors['neutral-400'],
      'border-glass': globalColors['transparent-white'],
      'border-primary': globalColors['blue-50'],
      'border-success': globalColors['green-200'],
    }
  }
}

const  semanticTokens = {
  colors: {
    // bg
    'bg-default': {
      default: tokens.colors.light['bg-default'],
      _dark: tokens.colors.dark['bg-default'],
    },
    'bg-light': {
      default: tokens.colors.light['bg-light'],
      _dark: tokens.colors.dark['bg-light'],
    },
    'bg-muted': {
      default: tokens.colors.light['bg-muted'],
      _dark: tokens.colors.dark['bg-muted'],
    },
    'bg-success': {
      default: tokens.colors.light['bg-success'],
      _dark: tokens.colors.dark['bg-success'],
    },
    'bg-success-strong': {
      default: tokens.colors.light['bg-success-strong'],
      _dark: tokens.colors.dark['bg-success-strong'],
    },
    'bg-primary': {
      default: tokens.colors.light['bg-primary'],
      _dark: tokens.colors.dark['bg-primary'],
    },
    'bg-primary-strong': {
      default: tokens.colors.light['bg-primary-strong'],
      _dark: tokens.colors.dark['bg-primary-strong'],
    },
    'bg-decorative-two': {
      default: tokens.colors.light['bg-decorative-two'],
      _dark: tokens.colors.dark['bg-decorative-two'],
    },
    'bg-decorative-purple': {
      default: tokens.colors.light['bg-decorative-purple'],
      _dark: tokens.colors.dark['bg-decorative-purple'],
    },
    'bg-decorative-purple-strong': {
      default: tokens.colors.light['bg-decorative-purple-strong'],
      _dark: tokens.colors.dark['bg-decorative-purple-strong'],
    },
    'bg-glass': {
      default: tokens.colors.light['bg-glass'],
      _dark: tokens.colors.dark['bg-glass'],
    },
    // text
    'text-weak': {
      default: tokens.colors.light['text-weak'],
      _dark: tokens.colors.dark['text-weak'],
    },
    'text-readable-weak': {
      default: tokens.colors.light['text-readable-weak'],
      _dark: tokens.colors.dark['text-readable-weak'],
    },
    'text-medium': {
      default: tokens.colors.light['text-medium'],
      _dark: tokens.colors.dark['text-medium'],
    },
    'text-default': {
      default: tokens.colors.light['text-default'],
      _dark: tokens.colors.dark['text-default'],
    },
    'text-primary-weak': {
      default: tokens.colors.light['text-primary-weak'],
      _dark: tokens.colors.dark['text-primary-weak'],
    },
    'text-primary': {
      default: tokens.colors.light['text-primary'],
      _dark: tokens.colors.dark['text-primary'],
    },
    'text-primary-medium': {
      default: tokens.colors.light['text-primary-medium'],
      _dark: tokens.colors.dark['text-primary-medium'],
    },
    'text-success': {
      default: tokens.colors.light['text-success'],
      _dark: tokens.colors.dark['text-success'],
    },
    'text-decorative-purple': {
      default: tokens.colors.light['text-decorative-purple'],
      _dark: tokens.colors.dark['text-decorative-purple'],
    },
    'text-on': {
      default: tokens.colors.light['text-on'],
      _dark: tokens.colors.dark['text-on'],
    },
    //border
    'border-light': {
      default: tokens.colors.light['border-light'],
      _dark: tokens.colors.dark['border-light'],
    },
    'border-glass': {
      default: tokens.colors.light['border-glass'],
      _dark: tokens.colors.dark['border-glass'],
    },
    'border-primary': {
      default: tokens.colors.light['border-primary'],
      _dark: tokens.colors.dark['border-primary'],
    },
    'border-success': {
      default: tokens.colors.light['border-success'],
      _dark: tokens.colors.dark['border-success'],
    },
  },
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Manrope Variable', sans-serif`,
},
fontSizes: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.125rem",
    lg: "1.2rem",
    xl: "1.3rem",
    "2xl": "1.6rem",
    "3xl": "1.875rem",
    "4xl": "2.5rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6.875rem",
    "9xl": "8rem",
    "10xl": '10rem',
},
lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.17,
    short: 1.3,
    base: 1.5,
    tall: 1.625,
    taller: "2rem",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
},
letterSpacings: {
    tighter: "-0.045em",
    tight: "-0.015em",
    normal: "0",
    wide: "0.01em",
    wider: "0.05em",
    widest: "0.1em",
    extra_widest: "0.125em",
},
fontWeights: {
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
},
space: {
    px: '1px',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
  },
}


const styles = {
  global: {
    body: {
      background: 'bg-default',
      color: 'text-default',
      fontFamily: 'body',
      fontSize: 'lg',
      lineHeight: 'base',
      letterSpacing: 'normal',
    },
  }
}

const theme = {
  styles,
  semanticTokens,
  textStyles,
}

export default extendTheme(theme)
