import { defineStyle } from '@chakra-ui/react';

const title_1 = defineStyle({
  fontFamily: 'heading',
  fontSize: '4xl',
  lineHeight: 'shorter',
  letterSpacing: 'normal',
  fontWeight: 'semiBold',
});

const title_2 = defineStyle({
  fontFamily: 'heading',
  fontSize: '3xl',
  lineHeight: 'shorter',
  letterSpacing: 'normal',
  fontWeight: 'bold',
});

const title_3 = defineStyle({
  fontFamily: 'heading',
  fontSize: '2xl',
  lineHeight: 'shorter',
  letterSpacing: 'normal',
  fontWeight: 'medium',
});

const title_mini = defineStyle({
  fontFamily: 'heading',
  fontSize: 'xs',
  lineHeight: 'base',
  letterSpacing: 'extra_widest',
  fontWeight: 'medium',
  textTransform: 'uppercase',
});

const subhead = defineStyle({
  fontFamily: 'heading',
  fontSize: '2xl',
  lineHeight: 'short',
  letterSpacing: 'normal',
  fontWeight: 'medium',
  fontStyle: 'italic',
});

const subhead_2 = defineStyle({
  fontFamily: 'heading',
  fontSize: '2xl',
  lineHeight: 'shorter',
  letterSpacing: 'normal',
  fontWeight: 'medium',
  fontStyle: 'italic',
});

const label = defineStyle({
  fontFamily: 'body',
  fontSize: 'sm',
  lineHeight: 'base',
  letterSpacing: 'normal',
  fontWeight: 'extraBold',
});

const caption = defineStyle({
  fontFamily: 'body',
  fontSize: 'xs',
  lineHeight: 'base',
  letterSpacing: 'normal',
});

const decorative_2xl = defineStyle({
  fontFamily: 'heading',
  fontSize: '9xl',
  lineHeight: 'shorter',
  fontWeight: 'extraBold',
});

const decorative_xl = defineStyle({
  fontFamily: 'heading',
  fontSize: '8xl',
  lineHeight: 'none',
  letterSpacing: 'tighter',
  fontWeight: 'semiBold',
});

const decorative_lg = defineStyle({
  fontFamily: 'heading',
  fontSize: '6xl',
  lineHeight: 'shorter',
  letterSpacing: 'tight',
  fontWeight: 'bold',
});

const body_regular = defineStyle({
  fontFamily: 'body',
  fontSize: 'lg',
  lineHeight: 'base',
  letterSpacing: 'normal',
});

const body_regular_medium = defineStyle({
  fontFamily: 'body',
  fontSize: 'lg',
  lineHeight: 'base',
  letterSpacing: 'normal',
  fontWeight: 'medium',
});

const body_regular_semiBold = defineStyle({
  fontFamily: 'body',
  fontSize: 'lg',
  lineHeight: 'base',
  letterSpacing: 'normal',
  fontWeight: 'semiBold',
});

const body_large = defineStyle({
  fontFamily: 'body',
  fontSize: '2xl',
  lineHeight: 'base',
  letterSpacing: 'normal',
});

const body_large_semiBold = defineStyle({
  fontFamily: 'body',
  fontSize: '2xl',
  lineHeight: 'base',
  letterSpacing: 'normal',
  fontWeight: 'semiBold',
});

const body_small = defineStyle({
  fontFamily: 'body',
  fontSize: 'sm',
  lineHeight: 'base',
  letterSpacing: 'normal',
  textDecorationThickness: "8px"
});

const body_small_medium = defineStyle({
  fontFamily: 'body',
  fontSize: 'sm',
  lineHeight: 'base',
  letterSpacing: 'normal',
  fontWeight: 'medium',
});

const nav_link = defineStyle({
  fontFamily: 'heading',
  fontSize: 'lg',
  color: 'text-medium',
})

const nav_link_selected = defineStyle({
  fontFamily: 'heading',
  fontSize: 'lg',
  fontWeight: 'medium',
  color: 'text-primary',
})

const textStyles = {
  title_1, 
  title_2, 
  title_3, 
  title_mini, 
  subhead,
  subhead_2,
  label,
  caption,
  decorative_2xl,
  decorative_xl,
  decorative_lg,
  body_regular,
  body_regular_medium,
  body_regular_semiBold,
  body_large,
  body_large_semiBold,
  body_small,
  body_small_medium,
  nav_link,
  nav_link_selected
}

export default textStyles;